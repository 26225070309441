import React from 'react'
import { ValidatorComponent } from 'react-form-validator-core'

class InputValidator extends ValidatorComponent {
  componentDidMount() {
    if (this.input.value !== '') {
      this.input.classList.add('input-not-empty')
    }

    // anti-pattern to make it work
    super.componentDidMount()
  }

  render() {
    const {
      icon,
      label,
      value,
      characterLimit,
      children,
      autoFocus,
      autoComplete,
      spellCheck,
      formGroupClassName,
      hasInputWrapper,
      inputWrapperProps,
      labelClassName,
      showLimit = true,
      multiple = false,
      ...rest
    } = this.props

    // why are these passed in if they are not used?
    delete rest.validators
    delete rest.requiredError
    delete rest.validatorListener
    delete rest.errorMessages

    const displayInput = () => (
      <input
        {...rest}
        value={value}
        ref={r => {
          this.input = r
        }}
        maxLength={characterLimit}
        autoComplete={autoComplete || 'on'}
        spellCheck={spellCheck || 'true'}
        autoFocus={autoFocus}
        multiple={multiple}
        onChange={event => {
          if (this.errorText()) {
            this.setState({isValid: true})
          }
          if (rest && rest.onChange) {
            rest.onChange(event)
          }
          if (event.target.value !== '') {
            event.target.classList.add('input-not-empty')
          } else {
            event.target.classList.remove('input-not-empty')
          }
        }}
      />
    )

    return (
      <div
        className={`form-group ${this.errorText() ? 'has-error' : ''} ${
          formGroupClassName ? formGroupClassName : ''
        }`}
      >
        {label && (
          <label className={labelClassName} htmlFor={rest && rest.id}>
            {icon && <i className={icon} />}
            {label}
          </label>
        )}
        {hasInputWrapper ? (
          <div className='input-wrapper' {...inputWrapperProps}>
            {displayInput()}
          </div>
        ) : (
          displayInput()
        )}

        {children}
        <div className='input-messages'>
          {this.errorText()}
          {characterLimit && showLimit && (
            <div className='input-char-limit'>
              Remaining characters: {characterLimit - (value?.length || 0)}
            </div>
          )}
        </div>
      </div>
    )
  }

  errorText() {
    const { isValid } = this.state

    if (isValid) {
      return null
    }

    return <div className='form-error-message'>{this.getErrorMessage()}</div>
  }
}

export default InputValidator
