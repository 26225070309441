import React, { ReactElement } from 'react'

export interface ModalProps {
  readonly modalIsOpen?: boolean
  readonly closeModal?: () => void
}

interface Props {
  readonly modalComponent: React.ReactElement
  readonly buttonComponent?: React.ReactElement
  readonly defaultOpen?: boolean
  readonly closeModal?: () => void
}

interface State {
  readonly modalIsOpen: boolean
}

export default class ModalOpen extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    const { defaultOpen } = this.props
    this.state = {
      modalIsOpen: !!defaultOpen,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  generateButton = (button): ReactElement =>
    React.cloneElement(button, {
      onClick: event => this.openModal(event),
    })

  generateModal = (modal: React.ReactElement): ReactElement => {
    const { modalIsOpen } = this.state
    const { closeModal } = this.props
    return React.cloneElement(modal, {
      modalIsOpen,
      closeModal: closeModal instanceof Function ? closeModal : this.closeModal,
    })
  }

  openModal = (event): void => {
    event.preventDefault()
    document.body.style.overflowY = 'hidden'
    this.setState({ modalIsOpen: true })
  }

  closeModal = (): void => {
    document.body.style.overflowY = 'initial'
    this.setState({ modalIsOpen: false })
  }

  render(): ReactElement {
    const { buttonComponent, modalComponent } = this.props
    return (
      <React.Fragment>
        {buttonComponent && this.generateButton(buttonComponent)}
        {this.generateModal(modalComponent)}
      </React.Fragment>
    )
  }
}
